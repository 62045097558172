<template>
    <section id="section-sobre">
        <b-container>
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-center mb-5">
                        <div class="band-img-container">
                            <div class="left-members">
                                <div class="band-member">
                                    <div class="name">
                                        <p class="text-medium text-md-right text-24">{{bandMembers[0].name}}</p>
                                    </div>
                                    <div class="instruments">
                                        <p class="text-thin text-md-right text-20">{{formatInstruments(bandMembers[0].instruments)}}</p>
                                    </div>
                                </div>
                                <div class="band-member">
                                    <div class="name">
                                        <p class="text-medium text-right text-24">{{bandMembers[1].name}}</p>
                                    </div>
                                    <div class="instruments">
                                        <p class="text-thin text-right text-20">{{formatInstruments(bandMembers[1].instruments)}}</p>
                                    </div>
                                </div>
                            </div>
                            <img class="img-fluid" src="@/assets/img/band.webp" alt="">
                            <div class="right-members">
                                <div class="band-member">
                                    <div class="name">
                                        <p class="text-medium text-24">{{bandMembers[2].name}}</p>
                                    </div>
                                    <div class="instruments">
                                        <p class="text-thin text-20">{{formatInstruments(bandMembers[2].instruments)}}</p>
                                    </div>
                                </div>
                                <div class="band-member">
                                    <div class="name">
                                        <p class="text-medium text-right text-md-left text-24">{{bandMembers[3].name}}</p>
                                    </div>
                                    <div class="instruments">
                                        <p class="text-thin text-right text-md-left text-20">{{formatInstruments(bandMembers[3].instruments)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <p class="text-justify mb-3">
                        A Limon Juice é uma banda independente, formada na região metropolitana de Porto Alegre por João Kosciuszko (guitarra e vocal), Arthur Figueira (guitarra), Luísa Avencourt (baixo) e Vinícius Schweitzer (bateria e vocal). Tudo começou com uma iniciativa do guitarrista Arthur, que já tinha a ideia de montar uma banda e foi apresentado ao vocalista João.
                    </p>

                    <div class="d-flex justify-content-end">
                        <figure class="w-50 text-18">
                            <blockquote>Eu torcia pra que saísse gente de um arbusto e fizesse banda comigo porque era realmente o que eu queria</blockquote>
                            <figcaption>- Arthur</figcaption>
                        </figure>
                    </div>

                    <p class="text-justify mb-3">
                        Ele também já conhecia a Luísa, que começou a tocar baixo por causa da banda. O primeiro single, <a class="text-black" href="https://open.spotify.com/track/3zKeQDwykXZ69PXkFtJ715?si=86cf7cebe3064e2d" target="_blank"><b>At Least I Told Her</b></a>, foi lançado ainda em 2019 seguido de <a class="text-black" href="https://open.spotify.com/track/3mfvm1Dwzi88hVQrxWH1l1?si=2fe7b44f514347d0" target="_blank"><b>In Paris</b></a> e da troca do então baterista pelo Vinícius, que antes tocava teclado.  A Limon Juice passou por uma revolução em sua linha musical, aproximando-se das influências de artistas como Arctic Monkeys e The Strokes.
                    </p>
                    <p class="text-justify mb-3">
                        Nessa época, os integrantes já pensavam em trabalhar num EP. Em outubro daquele ano, fizeram sua primeira apresentação oficial no IV Baile de Primavera CMA. Se apresentaram mais duas vezes: no Libastock, onde dividiram palco com a banda Bella e o Olmo da Bruxa, e na 34° Mostratec, maior feira de tecnologia da América Latina. 
                    </p>
                    <p class="text-justify mb-5">
                        No início de 2020, a banda lançou o single Valentine’s. Com a pandemia, foi um ano de evoluir musicalmente, fazer novas composições e preparar o primeiro EP, que vai ser lançado em 21 de maio de 2021. Limon Juice é ouvida em mais de 70 países e já conta com fãs ao redor do mundo.
                    </p>

                    <h2>FAQ</h2>
                    <p class="text-bold">
                        Por que <i>Limon</i> e não <i>Lemon</i>?
                    </p>
                    <p class="text-justify mb-3">É estilístico, um toque brasileiro. Já existia uma banda chamada Lemon Juice. Os integrantes tomaram limonada no primeiro ensaio.</p>

                    <p class="text-bold">Pretendem gravar em português?</p>
                    <p class="text-justify mb-3">Não.</p>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
    data() {
        return {
            bandMembers: [
                {
                    name: 'João Kosciuszko',
                    instruments: [
                        'Voz',
                        'Guitarra'
                    ]
                },
                {
                    name: 'Vinícius Schweitzer',
                    instruments: [
                        'Bateria',
                        'Voz'
                    ]
                },
                {
                    name: 'Arthur Figueira',
                    instruments: [
                        'Guitarra'
                    ]
                },
                {
                    name: 'Luísa Avencourt',
                    instruments: [
                        'Baixo'
                    ]
                }
            ]
        }
    },
    methods: {
        checkBandMembersOrder() {
            let width = document.body.clientWidth
            if (width <= 767) {
                this.$set(this.bandMembers, '1', 
                    { 
                        name: 'Arthur Figueira',
                        instruments: [
                            'Guitarra'
                        ]
                    }
                )
                this.$set(this.bandMembers, '2', 
                    {
                        name: 'Vinícius Schweitzer',
                        instruments: [
                            'Bateria',
                            'Voz'
                        ]
                    }
                )
            } else {
                this.$set(this.bandMembers, '2', 
                    { 
                        name: 'Arthur Figueira',
                        instruments: [
                            'Guitarra'
                        ]
                    }
                )
                this.$set(this.bandMembers, '1', 
                    {
                        name: 'Vinícius Schweitzer',
                        instruments: [
                            'Bateria',
                            'Voz'
                        ]
                    }
                )
            }
        },
        formatInstruments(instruments) {
            let text = ''
            for (let i = 0; i < instruments.length; i++) {
                const element = instruments[i]
                if (i > 0) {
                    text += ' e ' + element
                } else {
                    text += element
                }
            }
            return text
        }
    },
    mounted() {
        this.checkBandMembersOrder()
        window.addEventListener('resize', (e) => {
            this.checkBandMembersOrder()
        })
    }
}
</script>

<style lang="scss" scoped>
#section-sobre {
    padding: 32px 0px;

    .band-img-container {
        display: grid;
        grid-template-columns: max-content minmax(1px, max-content) max-content;
        column-gap: 16px;
        @media(max-width: 767px) {
            grid-template-columns: initial;
            grid-template-rows: max-content minmax(1px, max-content) max-content;
        }
        .left-members, .right-members {
            display: grid;
            grid-template-rows: 1fr 1fr;
            align-items: center;
            @media(max-width: 767px) {
                grid-template-rows: initial;
                grid-template-columns: 1fr 1fr;
                column-gap: 16px;
                align-items: flex-start;
                .text-24 {
                    font-size: 16px;
                    font-weight: bold !important;
                }
                .text-20 {
                    font-size: 16px;
                    font-weight: 400 !important;
                }
            }
        }
        .band-member {
            &:nth-child(1) {
                // position: relative;
                // top: 10px;
            }
        }
        img {
            // width: calc(50%);
        }
    }

    blockquote::before {
        content: open-quote;
    }
    blockquote::after {
        content: close-quote;
    }
    blockquote {
        quotes: "“" "”" "‘" "’";
    }
}
</style>