<template>
    <section id="section-musica">
        <b-container>
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-center mb-3">
                        <iframe src="https://open.spotify.com/embed/track/3zKeQDwykXZ69PXkFtJ715" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </b-col>
                <b-col>
                    <div class="d-flex justify-content-center mb-3">
                        <iframe src="https://open.spotify.com/embed/track/3mfvm1Dwzi88hVQrxWH1l1" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>    
                    </div>
                </b-col>
                <b-col>
                    <div class="d-flex justify-content-center mb-3">
                        <iframe src="https://open.spotify.com/embed/track/11xVLyjHUFKQnFKA7iSTrT" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#section-musica {
    height: 100%;
    background: black;
    padding: 32px 0px;
}
</style>