<template>
    <footer id="footer">
        <b-container>
            <b-row>
                <b-col>
                    <p class="text-20 text-gray text-center mb-3">limonjuiceband@gmail.com</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <div class="d-flex justify-content-center">
                        <a href="https://open.spotify.com/artist/0LBh9HasNBcW67qSYhbrkL?si=JEXwFJcLSjSNGyKezQYkDQ" class="icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/spotify" scale="1.75"/>
                        </a>

                        <a href="https://www.youtube.com/c/limonjuiceband" class="icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/youtube" scale="1.75"/>
                        </a>

                        <a href="http://instagram.com/limonjuiceband" class="icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/instagram" scale="1.75"/>
                        </a>

                        <a href="https://twitter.com/limonjuiceband" class="icon text-gray" target="_blank">
                            <v-icon name="brands/twitter" scale="1.75"/>
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#footer {
    flex-grow: 1;
    padding: 16px 0px;
    // background: var(--off-black);
    background: black;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>