<template>
    <header id="header" class="sticky-top">
        <b-container>
            <b-row>
                <b-col cols="3" md="2">
                    <div class="logo-container">
                        <img src="@/assets/img/limonjuice2-white-border-black.png" alt="">
                    </div>
                </b-col>

                <b-col md="7" class="d-none d-md-block">
                    <div class="h-100 d-none d-md-flex justify-content-between align-items-center">
                        <router-link to="/" class="link text-white text-16 text-medium" :class="{'text-gray': isRoute('/')}">
                            música
                        </router-link>
                        <router-link to="/sobre" class="link text-white text-16 text-medium" :class="{'text-gray': isRoute('/sobre')}">
                            sobre
                        </router-link>
                        <router-link to="/novidades" class="link text-white text-16 text-medium" :class="{'text-gray': isRoute('/novidades')}">
                            novidades
                        </router-link>
                        <router-link to="/galeria" class="link text-white text-16 text-medium" :class="{'text-gray': isRoute('/galeria')}">
                            galeria
                        </router-link>
                    </div>
                </b-col>

                <b-col cols="9" md="3" class="d-flex justify-content-end align-items-center">
                    <div class="d-none d-md-flex justify-content-end">
                        <a href="https://open.spotify.com/artist/0LBh9HasNBcW67qSYhbrkL?si=JEXwFJcLSjSNGyKezQYkDQ" class="social-icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/spotify" scale="1.75"/>
                        </a>

                        <a href="https://www.youtube.com/c/limonjuiceband" class="social-icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/youtube" scale="1.75"/>
                        </a>

                        <a href="http://instagram.com/limonjuiceband" class="social-icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/instagram" scale="1.75"/>
                        </a>

                        <a href="https://twitter.com/limonjuiceband" class="social-icon text-gray" target="_blank">
                            <v-icon name="brands/twitter" scale="1.75"/>
                        </a>
                    </div>

                    <div class="burger-container d-block d-md-none">
                        <div @click="isSidebarOpen = !isSidebarOpen" id="nav-icon" :class="{'open': isSidebarOpen}">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <transition>
            <div class="header-mobile" :class="{'open': isSidebarOpen}">
                <div class="header-mobile-container">
                    <b-container>
                        <b-row>
                            <b-col>
                                <div class="d-flex flex-column justify-content-between align-items-center">
                                    <router-link @click.native="isSidebarOpen = false" to="/" class="link text-white text-16 text-medium mt-4 mb-4" :class="{'text-gray': isRoute('/')}">
                                        música
                                    </router-link>
                                    <router-link @click.native="isSidebarOpen = false" to="/sobre" class="link text-white text-16 text-medium mb-4" :class="{'text-gray': isRoute('/sobre')}">
                                        sobre
                                    </router-link>
                                    <router-link @click.native="isSidebarOpen = false" to="/novidades" class="link text-white text-16 text-medium mb-4" :class="{'text-gray': isRoute('/novidades')}">
                                        novidades
                                    </router-link>
                                    <router-link @click.native="isSidebarOpen = false" to="/galeria" class="link text-white text-16 text-medium" :class="{'text-gray': isRoute('/galeria')}">
                                        galeria
                                    </router-link>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                    <div v-if="isSidebarOpen" class="social-icons d-flex justify-content-center">
                        <a href="https://open.spotify.com/artist/0LBh9HasNBcW67qSYhbrkL?si=JEXwFJcLSjSNGyKezQYkDQ" class="social-icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/spotify" scale="1.75"/>
                        </a>

                        <a href="https://www.youtube.com/c/limonjuiceband" class="social-icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/youtube" scale="1.75"/>
                        </a>

                        <a href="http://instagram.com/limonjuiceband" class="social-icon text-gray mr-3" target="_blank">
                            <v-icon name="brands/instagram" scale="1.75"/>
                        </a>

                        <a href="https://twitter.com/limonjuiceband" class="social-icon text-gray" target="_blank">
                            <v-icon name="brands/twitter" scale="1.75"/>
                        </a>
                    </div>
                </div>
            </div>
        </transition>
    </header>
</template>

<script>
export default {
    watch: {
        isSidebarOpen() {
            let appContent = document.getElementById('app-content')
            if (this.isSidebarOpen) {
                appContent.classList.add('overflow-hidden')
            } else {
                appContent.classList.remove('overflow-hidden')
            }
        }
    },
    data() {
        return {
            isSidebarOpen: false
        }
    },
    methods: {
        isRoute(path) {
            return path == this.$route.path
        },
        setupHeader() {
            let width = document.body.clientWidth
            if (width <= 767) {
                // console.log('oi')
            }
        }
    },
    mounted() {
        this.setupHeader()
        window.addEventListener('resize', () => {
            this.setupHeader()
        })
    }
}
</script>

<style lang="scss" scoped>
#header {
    padding: 16px 0px;
    background: black;
    .logo-container {
        img {
            height: 72px;
            border-radius: 50%;
        }
    }

    .link {
        text-decoration: none;
        transition: color .3s;
        &:hover {
            color: var(--gray) !important;
            transition: color .3s;
        }
    }

    .social-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .burger-container {
        margin-left: 16px;
        $barHeight: 5px;
        #nav-icon {
            width: 32px;
            height: calc(#{$barHeight} * 5);
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            span {
                display: block;
                position: absolute;
                height: $barHeight;
                width: 100%;
                background: white;
                border-radius: 8px;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;
                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),&:nth-child(3) {
                    top: calc(#{$barHeight} * 2);
                }

                &:nth-child(4) {
                    top: calc(#{$barHeight} * 2 * 2);
                }
            }

            &.open {
                span {
                    &:nth-child(1) {
                        top: calc(#{$barHeight} * 2);
                        width: 0%;
                        left: 50%;
                    }
                    &:nth-child(2) {
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    &:nth-child(3) {
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                    &:nth-child(4) {
                        top: calc(#{$barHeight} * 2);
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
    }
    
    .header-mobile {
        position: absolute;
        background: black;
        top: 96px;
        width: 100%;
        overflow: hidden;
        height: 0px;
        transition: height .25s ease-in-out;
        &.open {
            height: calc(var(--vh, 1vh) * 100 - 96px);
            transition: height .25s ease-in-out;
            .header-mobile-container {
                transition: opacity 1.5s;
                opacity: 1;
            }
        }
        .header-mobile-container {
            position: relative;
            height: 100%;
            padding: 16px;
            opacity: 0;
            .social-icons {
                position: absolute;
                left: 0px;
                bottom: 32px;
                width: 100%;
            }
        }

    }

}
</style>