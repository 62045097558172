import Vue from 'vue'
import VueRouter from 'vue-router'
import Musica from '@/views/Musica'
import Sobre from '@/views/Sobre'
import Novidades from '@/views/Novidades'
import Galeria from '@/views/Galeria'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Musica',
    component: Musica
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: Sobre
  },
  {
    path: '/novidades',
    name: 'Novidades',
    component: Novidades
  },
  {
    path: '/galeria',
    name: 'Galeria',
    component: Galeria
  },


  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  routes
})

export default router
