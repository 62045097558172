<template>
    <section id="section-novidades">
        <b-container>
            <b-row>
                <b-col cols="12" md="6">
                    <img class="img-fluid mb-4" src="@/assets/img/zoom.png"/>
                </b-col>
                <b-col cols="12" md="6">
                    <p class="text-white mb-3">A Limon Juice está preparando o lançamento de seu primeiro EP, que será lançado em 21 de maio e se chama 521.</p>
                    <p class="text-white text-justify mb-3">As músicas contam uma história real sobre se apaixonar por alguém, a partir de momentos distintos: de pensamentos sobre um possível futuro, com ou sem a pessoa amada, na inédita A Matter of Time; até festas que terminam antes da hora, na movimentada Cidade Baixa, em Spoiled Parties. Além dessas faixas, o EP conta com a também inédita Call Me Back When You Find Someone Quite as Desirable as Her e com regravações dos singles At Least I Told Her e In Paris.</p>
                    <p class="text-white text-justify mb-3">O EP ganhou vida no quarto do vocalista João, com cada integrante gravando separadamente o seu instrumento, e atualmente está em fase de mixagem pelo produtor Douglas Fontoura. A partir desse disco, um mix de estilos musicais, a banda pretende descobrir que tipo de sonoridade vai seguir nos próximos trabalhos.</p>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#section-novidades {
    padding: 32px 0px;
    background: black;
    flex-grow: 1;
}
</style>