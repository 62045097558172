<template>
    <section id="section-galeria">
        <b-container>
            <b-row>
                <b-col>
                    <swiper ref="swiper-1" class="swiper mb-3" :options="swiperOption">
                        <swiper-slide :key="img" v-for="img in bailePrimavera">
                            <div class="swiper-zoom-container">
                                <img :src="img">
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        <div @click="prev('swiper-1')" class="swiper-button-prev" slot="button-prev">
                            <v-icon name="chevron-left" scale="1.75"/>
                        </div>
                        <div @click="next('swiper-1')" class="swiper-button-next" slot="button-next">
                            <v-icon name="chevron-right" scale="1.75"/>
                        </div>
                    </swiper>
                    <p class="text-center mb-3">
                        Apresentação no IV Baile de Primavera CMA, em outubro de 2019
                    </p>
                    <p class="text-center mb-5">Fotos: Mateus Patussi</p>

                    <swiper ref="swiper-2" class="swiper mb-3" :options="swiperOption">
                        <swiper-slide :key="img" v-for="img in mostratec">
                            <div class="swiper-zoom-container">
                                <img :src="img">
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        <div @click="prev('swiper-2')" class="swiper-button-prev" slot="button-prev">
                            <v-icon name="chevron-left" scale="1.75"/>
                        </div>
                        <div @click="next('swiper-2')" class="swiper-button-next" slot="button-next">
                            <v-icon name="chevron-right" scale="1.75"/>
                        </div>
                    </swiper>
                    <p class="text-center mb-3">
                        Apresentação na 34° Mostratec, em outubro de 2019
                    </p>
                    <p class="text-center mb-5">Fotos: Davi Lopes</p>

                    <swiper ref="swiper-3" class="swiper mb-3" :options="swiperOption">
                        <swiper-slide :key="img" v-for="img in libastock">
                            <div class="swiper-zoom-container">
                                <img :src="img">
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        <div @click="prev('swiper-3')" class="swiper-button-prev" slot="button-prev">
                            <v-icon name="chevron-left" scale="1.75"/>
                        </div>
                        <div @click="next('swiper-3')" class="swiper-button-next" slot="button-next">
                            <v-icon name="chevron-right" scale="1.75"/>
                        </div>
                    </swiper>
                    <p class="text-center mb-3">
                        Apresentação no Libastock, em dezembro de 2019
                    </p>
                    <p class="text-center mb-5">Fotos: Arthur Postai</p>

                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
    data() {
        return {
            swiperOption: {
                zoom: true,
                // autoplay: {
                //     delay: 2500,
                //     disableOnInteraction: false
                // },
                pagination: {
                    el: '.swiper-pagination'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            bailePrimavera: [
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F1.jpg?alt=media&token=4b618060-c227-4296-a906-d0598b9cfb67',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F2.jpg?alt=media&token=8b10d2a6-6ce1-4289-af10-4776d3c1c621',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F3.jpg?alt=media&token=206803c2-8cf3-4159-b39a-ca36aca94d42',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F4.jpg?alt=media&token=0fe6ee00-0fb9-4046-af1c-ce77e1ffdd42',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F5.jpg?alt=media&token=42068b76-1dbd-4261-800d-60838c6d8041',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F6.jpg?alt=media&token=3370eef4-7da3-4f30-9d3c-e18ae9747a95',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F7.jpg?alt=media&token=74e29573-90cc-4708-b003-e499e66bcc32',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/baile-primavera%2F8.jpg?alt=media&token=b680a3e7-b5d5-413c-bc93-80a82dfbd8f4'
            ],
            mostratec: [
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F1.jpg?alt=media&token=e148a761-e5d7-4008-a95b-b65e3f067b3a',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F2.jpg?alt=media&token=1e849730-3b69-494b-a2d0-c468e30b9fb1',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F3.jpg?alt=media&token=2aed5611-e573-4428-ac70-619b997308a4',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F4.jpg?alt=media&token=b2a9dd5f-597f-4601-9bbb-ae2ad7fb4859',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F5.jpg?alt=media&token=4856f21b-a79c-450c-a13f-ad26ecf449fc',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F6.jpg?alt=media&token=4e9907bd-071d-491c-9607-ffa37b8a90e1',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F7.jpg?alt=media&token=0cea1e86-1b5f-4903-8b5c-dd4b6f1c8cdf',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/mostratec%2F8.jpg?alt=media&token=83fb0680-7b76-48e6-a085-5b13165d56c5'
            ],
            libastock: [
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F1.jpg?alt=media&token=6be0955b-239f-4566-8024-b550611b06d0',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F2.jpg?alt=media&token=ff24e3fa-a232-4e9e-ac6e-100863b15870',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F3.jpg?alt=media&token=56c8bf4a-cb41-4634-82e5-d97ae79ace02',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F4.jpg?alt=media&token=92aa4c91-4f0e-4f90-8d08-98b432f97fb9',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F5.jpg?alt=media&token=ab6ab2fc-58de-45a8-b83b-ae8747017ea3',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F6.jpg?alt=media&token=f143c47a-7252-4aac-a3f2-9d1eedfebd0e',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F7.jpg?alt=media&token=4d269fc5-75cc-4005-8029-963bb8680544',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F8.jpg?alt=media&token=ab25dd8c-2a46-4053-9d8f-ce543e98f5cd',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F9.jpg?alt=media&token=9d5247cc-6690-47f3-bc3b-578a2cac8a08',
                'https://firebasestorage.googleapis.com/v0/b/limonjuice-47914.appspot.com/o/libastock%2F10.jpg?alt=media&token=195e3e0f-4cfe-47d8-a159-30a1e96a6684'
            ]
        }
    },
    methods: {
        next(ref) {
            let swiper = this.$refs[ref]
            swiper.swiperInstance.slideNext()
        },
        prev(ref) {
            let swiper = this.$refs[ref]
            swiper.swiperInstance.slidePrev()
        }
    }
}
</script>

<style lang="scss" scoped>
#section-galeria {
    padding: 32px 0px;

    .swiper {
        height: 500px;
        @media(max-width: 576px) {
            height: 300px;
        }
        .swiper-slide {
        background: white;
        }
        .swiper-button-next, .swiper-button-prev {
            color: white !important;
            height: 40px;
            width: 40px;
            background: rgba(0,0,0,.5);
            transition: all .2s;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            &:hover {
                background: rgba(0,0,0,.75);
                transition: all .2s;
            }
            &::after {
                content: '';
            }
        }
    }
}
</style>