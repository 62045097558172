<template>
    <div id="app">
        <div id="app-content">
            <Header/>
            <main>
                <transition name="fade" mode="out-in">
                    <router-view/>
                </transition>
            </main>
            <Footer/>
        </div>
        <transition name="fade">
            <div v-if="isSplashScreenVisible" class="splash-screen">
                <div class="h-100 p-3 d-flex justify-content-center align-items-center">
                    <img class="img-fluid" width="192" src="@/assets/img/limon.gif" alt="">
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import Header from '@/components/template/Header'
import Footer from '@/components/template/Footer'

export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            isSplashScreenVisible: true
        }
    },
    mounted() {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        })

        

        window.addEventListener('load', (event) => {
            // setTimeout(() => {
                this.isSplashScreenVisible = false
            // }, 1000)
        })
    }
}
</script>

<style lang="scss" scoped>
#app {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    #app-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
    .splash-screen {
        background: var(--off-black);
        position: absolute;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        width: 100vw;
        top: 0px;
        z-index: 1021;

    }

    // animations
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
}
</style>
